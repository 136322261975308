.splash-screen {
    height: 100vh;
    width: 100%;
}
  
.splash-screen-overlay-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 10%;
    top: 10%;
    text-align: left;
}

@media (max-width: 768px) {
    .splash-screen-overlay-text {
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

.splash-screen-image {
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    position: absolute;
    object-fit: cover;
    z-index: -1;
    top: 0;
    right: 0;
    object-position: right;
}

.splash-screen-text {
    color: white;
    font-size: 80px;
    animation: fadeDown 1.5s;
}

@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.splash-screen-subtext {
    color: white;
    font-size: 40px;
    margin-top: 0px;
    animation: fadeDownSubtext 1.5s;
}

@keyframes fadeDownSubtext {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    50% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.splash-screen-note {
    text-align: center;
    color: white;
    font-size: 28px;
    position: absolute;
    top: 225px;
    left: 10%;
}

.animated-arrow {
    position: absolute;
    left: 50%;
    animation: fade 1.5s infinite;
    width: 50px;
    height: 50px;
    transform: translateX(-50%);
    bottom: 10px;
    cursor: pointer;


}

@keyframes fade {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

