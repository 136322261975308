.unity-canvas {
    width: 30%;
    height: 100%;
    background-color: #000;
    margin: 0 auto;
    display: block;
    position: relative;
    margin-top: 40%;

    margin-bottom: 20px;
    margin-top: 20px;
}