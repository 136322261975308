.navbar {
    padding: 10px;
    position: fixed;
    /* position: absolute; */
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 1000;
    top: 0;
    right: 0;
}

@media (max-width: 768px) {
    .navbar {
        width: calc(100% - 20px);
        align-items: center;
    }
}

.navbar-background {
    transition: background-color 0.5s ease;
    height: fit-content;
}

.navbar a {
    font-size: 18px;
    margin: 10px;
}

.light-text a {
    color: white;
}

.dark-text a {
    color: black;
}

.navbar li {
    text-underline-offset: 8px;
    color: #36d661;
    transition: color 0.5s;
    z-index: 1001;
}

.navbar li:hover {
    color: #36d661;
    text-decoration: underline;
}


.navbar li:hover {
    color: #36d661;
    text-decoration: underline;
    text-underline-offset: 8px;

}

.navbar-list {
    display: flex;
    list-style-type: none;
    margin-right: 40px;
    margin-top: 10px;
}

.is-selected {
    text-decoration: underline;
    text-underline-offset: 8px;
}