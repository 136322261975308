.magna-page-wrapper {
    width: 100%;
    height: 100vh;
    background-color: white;
    overflow-x: hidden;
    
}

.margin {
    margin-bottom: 50px;
}

.articleTitleLight {
    font-size: 60px;
    
    color: black;
    background-color: white;
    text-align: center;
    font-weight: bold;
}

.articleSubtitleLight {
    font-size: 30px;
    
    color: black;
    margin-top: 50px;
    text-align: center;
    font-weight: bold;
}

.magna-page-wrapper h3 {
    all: unset;
}

.magna-page-wrapper h1 {
    all: unset;
}

.medImage {
    width:50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;

}

.cap {
    text-align: center;
    font-size: 18px;
    
    color: black;
    margin-top: 5px;

}

/*===========================*/

.tileFrame {
    display: flex;
    height: 100%;
}

.left-column,
.right-column,
.main-column {
    height: 200px;
}

.left-column {
    flex: 0 0 10%;
    margin-top: -5%;

}

.main-column {
    flex: 0 0 80%;
    /* 80% width */
    margin-top: -5%;

}

.right-column {
    flex: 0 0 10%;
    /* 10% width */
    margin-top: -5%;
}

.tileFraming {
    width: 300%;
    margin-left: -100%;
}

.mainImage {
    width: 32%;
    display: block;
    margin-left: 42%;
    margin-top: -146px;
    z-index: -1;
}

.mainTextContainer {
    margin-left: 21%;
    margin-top: 20%;
    width: 60%;
}

.mainText {
    width: 45%;
    text-align: center;
    font-size: 25px;
    left: 49%;
    top: 40%;
}

/*===========================*/

.basketball-connection {
    column-count: 2;
    height: 100vh;

}


.basketball-connection-image {
    width: 200%;
    height: auto;
    margin-top: 50px;
    margin-left: -100%;
    margin-bottom: 50px;
    z-index: -1;

}



.secondTextContainer {
    margin-left: 27%;
    width: 60%;
    margin-top: 30%;
}

.secondText {
    width: 155%;
    text-align: center;
    font-size: 25px;
    left: 49%;
    top: 70%;
}

/*===========================*/

.playFlowColumns {
    column-count: 2;
    margin-top: 250px;
    height: 80vh;
}

.flowImage {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
}

.flowImage2 {
    width: 85%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
}


.flowText {
    text-align: center;
    font-size: 25px;

}

.flowTextContainer1 {
    width: 155%;
    margin-top: 25%;
    margin-left: -150%;
}

.flowTextContainer2 {
    width: 155%;
    margin-top: -14%;
    margin-left: -108%;
}

.flowTextContainer3 {
    width: 155%;
    margin-top: -23%;
    margin-left: -25%;
}

/*===========================*/

.infoGrid {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.infoGridRow {
    display: flex;
    height: 100%;
}

.infoGridItem {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.infoGridItemText {
    font-size: 20px;
    text-align: center;
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.gridImage {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

/*===========================*/

.ideInfoImage {
    margin-top: 200px;
    height: 100vh;
}

.ideImage {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.sectionText {
    text-align: center;
    font-size: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: bold;
}

.subsectionText {
    text-align: center;
    font-size: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: bold;
}

/*===========================*/

.videoDemo {
    margin-top: 200px;
}

.smallInfoText {
    text-align: center;
    font-size: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10%;
    margin-right: 10%;
}

.video {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

/*===========================*/

.logoImage {
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.diagram {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}