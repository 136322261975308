.columns-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
}

.columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;
}

.columns > * {
    flex: 1 1 200px;
}

@media (max-width: 768px) {
    .columns {
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        
    }

    .columns img {
        width: 100%;
    }
}

.force-column {
    flex: 1 1 100%;
}

.force-row {
    flex: 1 1 100%;
}

@media (max-width: 768px) {
    .reverse {
        flex-direction: column-reverse;
    }

    .no-margin-bottom {
        margin-bottom: 0;
    }
}