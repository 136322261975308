.widget {
    background-color: #212121;
    border-radius: 14px;
    font-size: 30px;
    margin: auto;
    width: 66%;
    color: white;
    cursor: pointer;
    min-height: 175px;
    margin-bottom: 25px;
}

.widget:hover {
    background-color: #2d2d2d;
}

.text-box {
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 20px;
    
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.widget-title {
    font-size: 30px;
    position: relative;
    top: 10px;
}

.widget-subtitle {
    font-size: 18px;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
}

.text-col {
    width: 67%;
    padding-left: 10px;
    display: grid;
    grid-template-rows: 50% 50%;
}

.image-col {
    width: 33%;
    display: flex;
}

.image {
    margin: auto;
    max-height: 150px;
    max-width: 150px;
    border-radius: 8px;
    position: relative;
}

.link {
    text-decoration: none;
}

.peeking-image {
    max-height: 35px;
    max-width: 45px;
    margin-left: 25px;
    margin-bottom: 10px;
    vertical-align: middle;
}

.peeking-images {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    transition: transform 0.2s ease;
}

.peeking-image:hover {
    transform: scale(1.15);
}

.peek-widget {
    width: 76%;
    margin: auto;
}

.peek-widget-inner:hover {
    background-color: #2d2d2d;
}

.peek-widget-inner {
    background-color: #212121;
    border-radius: 14px;
    font-size: 30px;
    color: white;
    cursor: pointer;
    margin-bottom: 25px;
    overflow-y: hidden;
    height: 350px;

}

.widget-bar {
    background-color: #32a852;
    border-radius: 14px;
    font-size: 30px;
    margin: auto;
    width: 50%;
    color: white;
    cursor: pointer;
    min-height: 65px;
    max-height: 100px;
    font-size: 20px;
    
    white-space:nowrap;
    display: flex;
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .widget-bar {
        width: 90%;
        margin-bottom: -25px;
    }
}

.widget-bar:hover {
    background-color: #36d661;
}

.widget-bar-image {
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
    
}

.widget-bar-title {
    font-size: 20px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.peeking-image-bar {
    max-height: 35px;
    margin-left: 25px;
    max-width: 45px;
    vertical-align: middle;
}

.peeking-widget-image-col {
    width: 100%;
    display: flex;
    height: 100%;
    height: 250px;
}

.peeking-widget-text-col {
    width: 50%;
    padding-left: 10px;
    display: grid;
    grid-template-rows: 50% 50%;
}

.peeking-widget-image {
    margin: auto;
    max-height: 250px;
    border-radius: 8px;
    position: relative;
    margin-top: 20px;
    max-width: 93%;
    top: 50%;
    transform: translateY(-50%);
    
}

.peeking-widget-title {
    font-size: 30px;
    top: 10px;
    text-align: center;
    margin-bottom: -25px;
}

.peeking-widget-container {
    height: 100%;
    width: 100%;
}