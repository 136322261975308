.chess-board {
    display: grid;
    grid-template-columns: repeat(8, 60px);
    grid-template-rows: repeat(8, 60px);
    border-collapse: collapse;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 3px solid #fff;
}

.subtitle {
    font-size: 30px;
    
    color: white;
    margin-top: -20px;
    text-align: center;
}

.black {
    background-color: #000;
}

.white {
    background-color: #fff;
}

.queen-safe {
    background-color: #32a852;
}

.queen-unsafe {
    background-color: red;
}

.queen-image {
    width: 54px;
    height: 54px;
    top: 3px;
    left: 3px;
    position: relative;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 20%;
    margin-right: 20%;

}

.button-container button {
    padding: 10px;
    margin: 20px;
    min-width: 250px;
}