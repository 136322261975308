.pdf-container {
    position: absolute;
    left: 0;
    width: 90%;
    height: 600px;
    overflow: auto;
    /* center horizontally */
    margin-left: 5%;
    margin-right: 5%;



    border-radius: 14px;

}
