.page-bottom {
    /* center horizontally */
    display: flex;

    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
}

.bottom-text-wrapper {
    width: 100%;
}

.bottom-text {
    text-align: left;
    color: white;
    font-size: 40px;
    width: 1%; /* Force to wrap text */
    margin-left: 5%;
}

.bottom-text-small {
    text-align: left;
    color: white;
    font-size: 18px;
    width: 50%;
}

.small-icon-row {
    display: flex;
    gap: 20px;
    margin-left: 5%;
}

.icon-small {
    max-height: 30px;
    cursor: pointer;
}

.icon-small:hover {
    transform: scale(1.15);
}

.horizontal-line-bottom {
    border: 1px solid white;
    width: 100%;
    margin-top: 20px;
    border-radius: 1px;
    margin-bottom: 20px;
}