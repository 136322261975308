.row-entry-row {
    display: flex;
    flex-direction: row;
    margin-left: 9%;
    margin-right: 9%;
    color: white;
    gap: 40px;
    align-items: center;
    margin-bottom: 40px;
    transition: transform .2s;
}

.row-entry-row:hover {
    transform: scale(1.02);
}

/* .row-entry-row:hover .horizontal-line {
    border: 1px solid #32a852;
} */

.row-entry-column {
    width: 100%
}

.row-entry-image {
    width: 130px;
}

.top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}

.row-entry-title {
    font-size: 30px;
}


@media (max-width: 768px) {
    .row-entry-row {
        flex-direction: column;
        gap: 4px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .top-row {
        height: 120px;
    }
}