@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&family=Pacifico&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Sedgwick+Ave+Display&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Yarndings+12+Charted&display=swap');

body {
  background-color: #000212;
  font-family: "Raleway", sans-serif;
  overflow-x: hidden;
}

.button {
  background-color: #32a852;
  border-radius: 14px;
  margin: auto;
  width: 66%;
  color: white;
  cursor: pointer;
  min-height: 65px;
  max-height: 100px;
  font-size: 20px;
  white-space:nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
  text-wrap: wrap;
}

.button-small {
  background-color: #32a852;
  border-radius: 14px;
  margin: auto;
  width: 66%;
  color: white;
  cursor: pointer;
  min-height: 45px;
  max-height: 80px;
  font-size: 15px;
  white-space:nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.button-small:hover {
  background-color: #36d661;
}

.button-neutral {
  background-color: #212121;
}

.button-neutral:hover {
  background-color: #32a852;
}

.button p {
  text-decoration: none;
}

.button:hover {
  background-color: #36d661;
}

.select {
  background-color: white;
  font-family: "Raleway", sans-serif;
  border-radius: 2px;
  margin: auto;
  width: 66%;
  color: black;
  cursor: pointer;
  min-height: 25px;
  white-space:nowrap;
  text-decoration: none;
  border: none;
  transition: background-color 0.3s;
}

.select:hover {
  background-color: #bcbcbc;
}

.table td:nth-child(2) {
  padding-left: 20px;
}