.travel-map {
    height: 100vh;
    overflow-y: hidden;
}

.side-bar {
    /* sticky side bar */
    top: 0;
    height: calc(100vh - 0px);
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 10px;
    color: white;
    background-color: #000212;
    min-width: 150px;
    margin-bottom: 30px;
    position: fixed;
    z-index: 10000;
    left: 0;
}

@media (max-width: 768px) {

    .map .leaflet-control-zoom {
        display: none;
    }

    .side-bar {
        height: 50vh;
        bottom: 0;
        top: auto;
        width: 100%;
        text-align: center;
        margin-bottom: 0px;
        padding-bottom: 10px;
        padding-left: 0;
    }

    .side-bar .table {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
    }

    .select {
        width: 30%;
    }
}

.nav-background {
    background-color: #2d2d2d;
    width: 100%;
    height: 65px;
    top: 0;
    left: 0;
    z-index: 1000;
    position: fixed;
    visibility: hidden;
}

.plots {
    overflow-y: auto;
    height: 100vh;
    width: calc(100% - 150px);
    padding-left: 150px;
}

@media (max-width: 768px) {
    .plots {
        width: 100%;
        padding-left: 0;
        margin-top: 80px;
        text-align: center;
    }
}

.sidebar-button {
    position: fixed;

    left: 200px;
    z-index: 10000;
    bottom: 10px;
    transition: left 0.3s;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #474747;
    font-size: 20px;
}


.sidebar-button-left {
    left: 10px;
}

@media (max-width: 768px) {

    .sidebar-button {
        bottom: calc(50vh - 40px);
        left: 10px;
        right: auto;
        transition: bottom 0.3s;
        
    }

    .sidebar-button-left {
        left: 10px;
        bottom: 10px;
    }

}
