
.maze-board {
    display: grid;
    grid-template-columns: repeat(25, 25px);
    grid-template-rows: repeat(20, 25px);
    padding: 1px;
    border-collapse: collapse;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 20px;
    margin-top: 20px;
}

.maze-cell {
    background-color: #fff;
}

.maze-start-cell {
    background-color: red;
}

.maze-end {
    background-color: #32a852;
}

.maze-wall {
    background-color: #000;
}

.maze-path {
    background-color: blue;
}

.maze-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: 20%;
    margin-right: 20%;
}

.maze-wall-button {
    background-color: #000;
    color: #fff;
    border: 3px solid #fff;
}

.maze-wall-button:hover {
    background-color: #000;
    color: #fff;
    border: 3px solid #fff;
    transform: translateY(-5px);
}

.maze-wall-button-selected {
    background-color: #000;
    color: #fff;
    border: 3px solid #fff;
    transform: translateY(-5px);
}

.maze-wall-button-selected:hover {
    background-color: #000;
    color: #fff;
    border: 3px solid #fff;
    transform: translateY(-5px);
}

.maze-start-button {
    background-color: red;
    color: #fff;
}

.maze-start-button:hover {
    background-color: red;
    color: #fff;
    transform: translateY(-5px);
}

.maze-start-button-selected {
    background-color: red;
    color: #fff;
    transform: translateY(-5px);
}

.maze-start-button-selected:hover {
    background-color: red;
    color: #fff;
    transform: translateY(-5px);
}

.maze-end-button {
    background-color: #32a852;
    color: #fff;
}

.maze-end-button:hover {
    background-color: #32a852;
    color: #fff;
    transform: translateY(-5px);
}

.maze-end-button-selected {
    background-color: #32a852;
    color: #fff;
    transform: translateY(-5px);
}

.maze-end-button-selected:hover {
    background-color: #32a852;
    color: #fff;
    transform: translateY(-5px);
}

.maze-open-button {
    background-color: #fff;
    color: #000;
}

.maze-open-button:hover {
    background-color: #fff;
    color: #000;
    transform: translateY(-5px);
}

.maze-open-button-selected {
    background-color: #fff;
    color: #000;
    transform: translateY(-5px);
}

.maze-open-button-selected:hover {
    background-color: #fff;
    color: #000;
    transform: translateY(-5px);
}

.maze-buttons button {
    padding: 10px;
    margin: 20px;
    min-width: 250px;
}
