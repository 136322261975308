.general-text {
    color: white;
    text-align: center;
    font-size: 24px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 20px;
}

.width-constrain {
    max-width: 25%;
}

@media (max-width: 768px) {
    .width-constrain {
        max-width: 100%;
    }
}