.back-button {
    position: fixed;
    top: 0;
    left: 0;
    margin: 1rem;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
/*     
    font-size: 40px;
    color: white;
    text-decoration:none;
    font-weight: 600;
    background-color: transparent; */
    margin-top: 30px;
    max-width: 35px;
}

.back-button:hover {
    transform: scale(1.15);
}

@media (max-width: 768px) {
    .back-button {
        display: none;
    }
}

.back-button-light {
    position: fixed;
    top: 0;
    left: 0;
    margin: 1rem;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
    
    font-size: 80px;
    color: black;
    text-decoration:none;
    font-weight: 600;
    background-color: transparent;
}

.back-button-light:hover {
    transform: scale(1.15);
}