.articleContainer {
    margin-left: auto;
    margin-right: auto;
}

.articleTitle {
    font-size: 60px;
    
    color: white;
    margin-top: 50px;
    text-align: center;

}

@media (max-width: 768px) {
    .articleTitle {
        font-size: 40px;
        /* margin-top: 100px; */
    }
}

.articleSubtitle {
    font-size: 30px;
    
    color: white;
    margin-top: 50px;
    text-align: center;
}

@media (max-width: 768px) {
    .articleSubtitle {
        font-size: 20px;
        margin-top: 10px;
    }
}

.tableOfContentsColumn {
    float: left;
    width: auto;
}

.hamburgerButton {
    font-size: 69px;
    
    color: white;
    left: 0;
    bottom: 0;
    padding: 20px;
    text-align: left;
    position: fixed;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
}

@media (max-width: 768px) {
    .hamburgerButton {
        display: none;
    }
}

.hamburgerButton:hover {
    transform: scale(1.15);
}

.articleColumn {
    width: 100%;
}

.articleColumnWide{
    width: 100%;
    float: right;
}

.articleContent h2 {
    font-size: 42px;
    
    color: white;
    text-align: center;
}

.articleContent p {
    font-size: 20px;
    
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: justify;
}

@media (max-width: 768px) {
    .articleContent p {
        font-size: 16px;
        text-align: left;
    }
}

.articleContent figcaption {
    font-size: 20px;
    
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    margin-top: -40px;

}

@media (max-width: 768px) {
    .articleContent figcaption {
        font-size: 16px;
        margin-top: -45px;
    }
}

.articleContent img {
    width: 50%;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .articleContent img {
        width: 100%;
    }
}

.articleContent iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    margin-bottom: 50px;
    border-radius: 14px;
}

@media (max-width: 768px) {
    .articleContent iframe {
        margin-bottom: 50px;
    }
}

.articleContent button {
  background-color: #32a852;
  border-radius: 14px;
  margin: auto;
  width: 66%;
  color: white;
  cursor: pointer;
  min-height: 65px;
  max-height: 100px;
  font-size: 20px;
  
  white-space:nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: none;
}

.articleContent button:hover {
    background-color: #36d661;
}

.tableOfContents {
    position: fixed;
    font-size: 26px;
    
    color: white;
    text-align: left;
    margin-top: 30px;
    margin-left: -20px;
}

.tableOfContentsColumn li {
    margin-bottom: 20px;
}

.tableOfContentsColumn li:hover {
    font-weight: bold;
}


a:link {
    text-decoration: none !important;
}

.spacer {
    padding: 1px;    
}

.github {
    max-width: 65px;
    top: 35px;
    right: 25px;
    position: fixed;
    cursor: pointer;
}

@media (max-width: 768px) {
    .github {
        max-width: 50px;
        position: absolute;
        
        display: none;
    }
}

.github:hover {
    transform: scale(1.15);
}