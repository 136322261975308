.App {
    text-align: center;
}

h1 {
    margin-bottom: 20px;
}

.game-board {
    display: grid;
    grid-template-columns: repeat(30, 25px);
    grid-template-rows: repeat(20, 25px);
    padding: 1px;
    border-collapse: collapse;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 20px;
    margin-top: 20px;
}


.cell {
    background-color: #fff;
}

.snake {
    background-color: #333;
}

.apple {
    background-color: red;
}

.score {
    color: white;
    font-size: 20px;
    
    margin-top: -20px;
}

.start {
    width: 30%;
}

.enemy-snake {
    background-color: purple;
}

.enemy-snake-killable {
    background-color: blue;
}