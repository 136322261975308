.message {
    text-align: center;
    color: white;
    
    width: 68%;
    font-size: 40px;

    position: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}

@media (max-width: 768px) {
    .message {
        font-size: 28px;
    }
}