
.detail-widget {
    margin: 80px;
}

.detail-widget:hover .horizontal-line {
    border: 1px solid #32a852;
}

@media (max-width: 768px) {
    .detail-widget {
        margin: 20px;
    }
}

.link-wrapper {
    display: grid;
    grid-template-columns: 66% 33%;
}

@media (max-width: 768px) {
    .link-wrapper {
        grid-template-columns: 100%;
    }
}

.detail-widget-text {
    text-decoration: none;
    text-align: left;
    color: white;
    width: 66%;
    margin-left: 5%;
}

@media (max-width: 768px) {
    .detail-widget-text {
        width: 100%;
        margin-left: 0;
    }
}

.detail-widget-title {
    font-size: 30px;
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .detail-widget-title {
        max-width: 80%;
    }
}

.detail-widget-image {
    width: 400px;
    height: 300px;
    max-height: 300px;
    border-radius: 8px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .detail-widget-image {
        margin: auto;
        height: 200px;
        width: 267px;
        margin-bottom: 20px;
    }
}

.horizontal-line {
    border: 1px solid white;
    width: 100%;
    margin-top: 20px;
    border-radius: 1px;
    margin-bottom: 20px;
    transition:cubic-bezier(1, 0, 0, 1) 0.3s border;
}

.peeking-images-detail {
    position: relative;
    height: 10%;
    width: 100%;
    margin-top: 20px;
    transition: transform 0.2s ease;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .peeking-images-detail {
        margin-left: -6%;
        width: 112%;
    }
}