.title {
    font-size: 60px;
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.white-text {
    color: white;
}

.narrow {
    max-width: 85%;
    margin-left: 7.5%;
    margin-right: 7.5%;
}