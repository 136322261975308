.full-widget {
    border-radius: 14px;
    font-size: 30px;
    color: white;
    min-height: 175px;
    margin-bottom: 25px;
    margin-right: 10px;
    margin-left: 10px;
}

@media (min-width: 768px) {
    .full-widget {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
}

.content {
    margin-left: 50px;
    margin-right: 50px;
}

@media (max-width: 768px) {
    .content {
        margin-left: 5px;
        margin-right: 5px;
    }
}
